import React, { useEffect, useState } from 'react';
import DataTableComponent from '../../../components/DataTable/DataTableComponent';
import { Link } from 'react-router-dom';
import { deletePage, getPages } from '../../../service/api';
import Swal from 'sweetalert2';
import { getUserToken } from '../../../utils/auth';
import { getPageURL } from '../../../utils/page';

const Pages = () => {
	const [pages, setPages] = useState([]);

	const columns = [
		{
			name: 'Page Name',
			selector: (row) => row.name,
			sortable: true,
		},

		{
			name: 'Page Slug',
			selector: (row) => getPageURL(row),
			sortable: true,
			wrap: true,
		},
		{
			name: 'Level',
			selector: (row) => row.level,
			sortable: true,
		},
		{
			name: 'Actions',
			cell: (row) => (
				<div className="d-flex align-items-center justify-content-center">
					<Link
						to={
							row.level <= 2
								? '#'
								: `/admin/pages/edit/${row._id}`
						}
						className="btn btn-outline-info p-1 m-1 text-nowrap"
						style={{ cursor: 'pointer' }}
					>
						Update
					</Link>
					<button
						onClick={() => handleDelete(row._id)}
						className="btn btn-outline-danger p-1 m-1 text-nowrap"
						disabled={row.level <= 2}
						style={{ cursor: 'pointer' }}
					>
						Delete
					</button>
				</div>
			),
			sortable: false,
		},
	];

	const handleDelete = async (id) => {
		const confirmed = await Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this page!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, cancel!',
			reverseButtons: true,
		});

		if (confirmed.isConfirmed) {
			try {
				const response = await deletePage({
					id,
					token: getUserToken(),
				});
				if (response.success) {
					const pagesList = pages.filter((pages) => pages._id !== id);
					setPages(pagesList);
					Swal.fire(
						'Deleted!',
						'The pages has been deleted.',
						'success',
					);
				}
			} catch (error) {
				console.log('Error deleting pages:', error);
				Swal.fire(
					'Error!',
					'An error occurred while deleting the pages.',
					'error',
				);
			}
		} else if (confirmed.dismiss === Swal.DismissReason.cancel) {
			Swal.fire('Cancelled', 'The Pages deletion was cancelled.', 'info');
		}
	};

	useEffect(() => {
		const fetchPages = async () => {
			const response = await getPages();
			if (response.success) {
				setPages(response.data);
			}
		};
		fetchPages();
	}, []);

	return (
		<>
			<div className="mt-5 mx-md-5 mx-3 card rounded-lg shadow-sm">
				<div className="card-header d-flex justify-content-between h3">
					<span className="d-md-block d-none"></span>
					<span className="text-primary">Pages</span>

					<Link
						to="/admin/pages/add"
						className="btn btn-outline-primary"
					>
						Add Pages
					</Link>
				</div>
				<div className="card-body">
					<div className="">
						{pages && pages.length > 0 ? (
							<DataTableComponent
								columns={columns}
								data={pages}
								showSearch={true}
								searchKey="name"
							/>
						) : (
							<div className="bg-white text-center mt-5 p-4">
								Loading ...
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Pages;

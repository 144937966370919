import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import FormikForm from './FormikForm/FormikForm';
import { addContent, getPages, updateContent } from '../service/api';
import { getPageURL } from '../utils/page';

const ContentForm = ({ content }) => {
	const [pages, setPages] = useState([]);
	const navigate = useNavigate();

	const initialValues = {
		pageURL: content ? content.pageURL : '',
		content: content ? content.content : '',
	};

	const formFields = [
		{
			type: 'select',
			label: 'Content URL',
			name: 'pageURL',
			options: pages,
			fieldSize: 'md',
		},
		{
			type: 'editor',
			label: 'Content Description',
			name: 'content',
			fieldSize: 'md',
		},
	];

	const validationSchema = Yup.object().shape({
		pageURL: Yup.string().required('Page URL is required'),
		content: Yup.string().required('Content Description is required'),
	});

	const onSubmit = async (values, { resetForm }) => {
		const contentData = {
			pageURL: values.pageURL,
			content: values.content,
		};

		try {
			if (content) {
				await updateContent(content._id, contentData);
			} else {
				await addContent(contentData);
			}
			resetForm();
			navigate('/admin/contents');
		} catch (error) {
			console.log('Content creation/update failed.', error);
		}
	};

	useEffect(() => {
		const fetchPages = async () => {
			const response = await getPages();
			if (response.success) {
				const pagesURLs = response.data.map((page) => ({
					label: getPageURL(page),
					value: getPageURL(page),
				}));

				setPages(pagesURLs);
			}
		};
		fetchPages();
	}, []);

	return (
		<div className="container mt-5">
			<div className="m-5 card rounded-lg shadow-lg row pt-4">
				<div className="login-header">
					<h4 className="text-center text-primary">
						{content ? 'Edit Content' : 'Add Content'}
					</h4>
				</div>
				<hr className="mb-2" />
				<div className="card-body">
					{pages && pages.length > 0 ? (
						<FormikForm
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={onSubmit}
							centerFields={false}
							fields={formFields}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ContentForm;

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import FormikForm from './FormikForm/FormikForm';
import { useNavigate } from 'react-router-dom';
import { updatePage, getPagesByLevel } from '../service/api';

const UpdatePageForm = ({ page }) => {
	const [provinceId, setProvinceId] = useState('');
	const [levelTwoPages, setLevelTwoPages] = useState([]);
	const [levelThreePages, setLevelThreePages] = useState([]);
	const [pageType, setPageType] = useState('');
	const [pageLevel, setPageLevel] = useState(2);
	const [selectedFields, setSelectedFields] = useState([]);
	const [initialValues, setInitialValues] = useState({
		...page,
	});
	const [departments, setDepartments] = useState([]);
	const [cities, setCities] = useState([]);
	const navigate = useNavigate();

	const handleTypeChange = (event) => {
		setPageType(event.target.value);

		if (
			[
				'Cities',
				'Departments',
				'Professions',
				'Newspapers',
				'Service Commission',
				'Armed Forces',
			].includes(event.target.value)
		) {
			setPageLevel(3);
		} else {
			setPageLevel(4);
		}
	};

	const formFields = [
		{
			pageType: ['Cities'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 3,
					max: 3,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Departments'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 3,
					max: 3,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Professions'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 3,
					max: 3,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Newspapers'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 3,
					max: 3,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Service Commission'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 3,
					max: 3,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Armed Forces'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 3,
					max: 3,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Profession in City'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 4,
					max: 4,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Profession in Province'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 4,
					max: 4,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Profession in Department'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 4,
					max: 4,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Department in City'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 4,
					max: 4,
					value: pageLevel,
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
		{
			pageType: ['Federal Department in Province'],
			fields: [
				{
					label: 'Page Name',
					type: 'text',
					name: 'name',
					fieldSize: 'md',
				},
				{ label: 'Slug', type: 'text', name: 'slug', fieldSize: 'md' },
				{
					label: 'Level',
					type: 'number',
					name: 'level',
					min: 4,
					max: 4,
					value: { pageLevel },
					disabled: true,
					fieldSize: 'md',
				},
			],
		},
	];

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Page Name is required'),
		slug: Yup.string().required('URL Slug is required'),
	});

	const pageTypeOptions = [
		{ value: '', label: 'Select Page Type' },
		{ value: 'Cities', label: '1. Cities' },
		{ value: 'Departments', label: '2. Departments' },
		{ value: 'Professions', label: '3. Professions' },
		{ value: 'Newspapers', label: '4. Newspapers' },
		{
			value: 'Service Commission',
			label: '5. Hiring Agency/Service Commission',
		},
		{ value: 'Armed Forces', label: '6. Armed Forces' },
		{ value: 'Profession in City', label: '7. Profession in City' },
		{ value: 'Profession in Province', label: '8. Profession in Province' },
		{
			value: 'Profession in Department',
			label: '9. Profession in Department',
		},
		{ value: 'Department in City', label: '10. Department in City' },
		{
			value: 'Federal Department in Province',
			label: '11. Federal Department in Province',
		},
	];

	const handleSubmit = async (values) => {
		try {
			const selectedValues = Object.keys(values)
				.filter((key) =>
					selectedFields.find((field) => field.name === key),
				)
				.reduce((obj, key) => {
					obj[key] = values[key];
					return obj;
				}, {});
			selectedValues.level = pageLevel;
			selectedValues.pageType = pageType;
			if (selectedValues.level === 3) {
				selectedValues.isCategory = true;
			}
			if (pageType == 'Cities') {
				selectedValues.isCity = true;
			}
			if (pageType === 'Departments') {
				selectedValues.isDepartment = true;
			}
			if (pageType == 'Professions') {
				selectedValues.isProfession = true;
			}
			if (pageType == 'Newspapers') {
				selectedValues.isNewspaper = true;
			}
			if (pageType == 'Service Commission') {
				selectedValues.isServiceCommission = true;
			}
			if (pageType == 'Armed Forces') {
				selectedValues.isArmedForces = true;
			}
			if (pageType == 'Profession in City') {
				selectedValues.isProfessionInCity = true;
			}
			if (pageType == 'Profession in Province') {
				selectedValues.isProfessionInProvince = true;
			}
			if (pageType == 'Profession in Department') {
				selectedValues.levelTwoParent = selectedValues.province;
				selectedValues.levelThreeParent = selectedValues.department;
				selectedValues.isProfessionInDepartment = true;
			}
			if (pageType == 'Department in City') {
				selectedValues.levelTwoParent = selectedValues.province;
				selectedValues.levelThreeParent = selectedValues.department;
				selectedValues.isDepartmentInCity = true;
			}
			if (pageType == 'Federal Department in Province') {
				selectedValues.levelThreeParent = selectedValues.department;
				selectedValues.isFederalDepartmentInProvince = true;
			}

			const response = await updatePage(page._id, selectedValues);
			if (response.success) {
				navigate('/admin/pages');
			}
		} catch (error) {
			console.log('Page addition failed.', error);
		}
	};
	console.log(page, initialValues);

	useEffect(() => {
		if (page) {
			setPageType(page.pageType || '');
			setPageLevel(page.level);
		}
	}, [page]);

	useEffect(() => {
		const fetchLevelTwoPages = async () => {
			const response = await getPagesByLevel(2);
			if (response.success) {
				setLevelTwoPages(response.data);
			}
		};
		const fetchLevelThreePages = async () => {
			const response = await getPagesByLevel(3);
			if (response.success) {
				setLevelThreePages(response.data);
			}
		};

		fetchLevelTwoPages();
		fetchLevelThreePages();

		const initialValues = formFields.reduce((acc, fieldGroup) => {
			fieldGroup.fields.forEach((field) => {
				acc[field.name] = '';
			});
			return acc;
		}, {});

		setInitialValues(initialValues);
	}, []);

	useEffect(() => {
		const fields = formFields.find((field) =>
			field.pageType.includes(pageType),
		);
		if (fields) {
			setSelectedFields(fields.fields);
		}
		setProvinceId('');
	}, [pageType]);

	useEffect(() => {
		const initialVals = {};
		selectedFields.forEach((field) => {
			initialVals[field.name] = '';
		});
		setInitialValues(initialVals);
	}, [selectedFields, provinceId]);

	useEffect(() => {
		const departmentList = levelThreePages.filter(
			(page) =>
				page.isDepartment && page.levelTwoParent._id === provinceId,
		);

		let citiesList = [];
		if (
			provinceId === '66c1c4077990143de25aaac5' ||
			provinceId === '66c1c3907990143de25aaac4' ||
			provinceId === '66c1c4607990143de25aaac6'
		) {
			citiesList = levelThreePages.filter((page) => page.isCity);
		} else {
			citiesList = levelThreePages.filter(
				(page) => page.isCity && page.levelTwoParent._id === provinceId,
			);
		}

		setDepartments([...departmentList]);
		setCities([...citiesList]);
		const initialVals = {};
		selectedFields.forEach((field) => {
			if (field.name === 'city') {
				field.options = citiesList.map((parentPage) => ({
					label: parentPage.name,
					value: parentPage._id,
				}));
			}
			if (field.name === 'department') {
				field.options = departmentList.map((parentPage) => ({
					label: parentPage.name,
					value: parentPage._id,
				}));
			}
			initialVals[field.name] = '';
		});
		setInitialValues(initialVals);
	}, [provinceId]);

	return (
		<>
			<div className="container mt-5">
				<div className="m-2 card rounded-lg shadow-lg row pt-4">
					<div className="login-header ">
						<h4 className="text-center text-primary ">
							{page ? 'Edit Page' : 'Add Page'}
						</h4>
					</div>
					<hr className="mb-2" />
					<div className="card-body">
						<FormikForm
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
							title={page ? 'Edit Page' : 'Add Page'}
							centerFields={true}
							fields={[...selectedFields]}
						>
							<div>
								<label
									htmlFor="pageType"
									className="form-label"
								>
									Type of Page
								</label>
								<select
									className="form-select "
									id="pageType"
									name="pageType"
									value={pageType}
									onChange={handleTypeChange}
								>
									{pageTypeOptions.map((option) => (
										<option
											key={option.value}
											value={option.value}
											label={option.label}
										/>
									))}
								</select>
								<div
									name="err-typeOfPage"
									className="error-message"
								></div>
							</div>
						</FormikForm>
					</div>
				</div>
			</div>
		</>
	);
};

export default UpdatePageForm;

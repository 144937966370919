import React, { useEffect, useState } from 'react';
import DataTableComponent from '../../components/DataTable/DataTableComponent';
import { Link } from 'react-router-dom';
import { getCountProfessionPages } from '../../service/api';

const JobsByProfession = () => {
	const [jobs, setJobs] = useState([]);

	const columns = [
		{
			name: 'Job Profession',
			selector: (row) => row.title,
			cell: (row) => (
				<div className="d-flex align-items-center justify-content-center ">
					<Link
						to={`/Jobs-by-Profession/${row.slug}`}
						className=" text-nowrap"
					>
						{row.name}
					</Link>
				</div>
			),
			sortable: false,
		},
		{
			name: 'No. of Active Jobs',
			selector: (row) => row?.jobCount,
			cell: (row) => (
				<div className="d-flex align-items-center justify-content-center ">
					{row?.jobCount ? row?.jobCount : 'N/A'}
				</div>
			),
			sortable: false,
		},
	];

	useEffect(() => {
		const fetchJobs = async () => {
			const response = await getCountProfessionPages();
			if (response?.success) {
				const jobsData = response.data;

				setJobs(jobsData);
			}
		};
		fetchJobs();
	}, []);

	return (
		<section className="my-5">
			<div className="bg-light-1 py-5">
				<div className="container">
					{jobs && jobs.length ? (
						<div className="row m-2 ">
							<DataTableComponent columns={columns} data={jobs} />
						</div>
					) : (
						<div className="bg-white text-center mt-5 p-4">
							Loading ...
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default JobsByProfession;

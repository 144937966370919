import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import OrganizationSchema from '../components/SchemaMarkups/OrganizationSchema';
import PageContent from '../components/PageContent';

const Layout = () => {
	return (
		<div className="min-vh-100">
			<Header />
			<main className="flex-grow-1">
				<Outlet />
			</main>
			<FloatingWhatsApp
				phoneNumber="923224873016"
				accountName="Sarkaar"
				statusMessage="Online"
				allowClickAway={true}
				allowEsc={true}
				messageDelay={0}
			/>
			<OrganizationSchema />
			<PageContent />
			<Footer />
		</div>
	);
};

export default Layout;

import { apiRequest } from '../fetch';

export const getPages = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getPagesByLevel = async (level) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/level/${level}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getProvinces = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/provinces`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const addPage = async (payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'POST',
			url: '/page',
			data: JSON.stringify({ ...payload }),
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const updatePage = async (pageId, payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'PUT',
			url: `/page/${pageId}`,
			data: JSON.stringify({ ...payload }),
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const deletePage = async (payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'DELETE',
			url: '/page/' + payload.id,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getDepartmentPages = async (provinceId) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/department/${provinceId}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getArmedForcesPages = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/armed-forces`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getProfessionPages = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/profession`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getAllProfessionPages = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/profession/all`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};


export const getCountProfessionPages = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/profession/count`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getProfessionProvincePages = async (provinceId) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/profession/${provinceId}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getNewspaperPages = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/newspaper`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		// throw error;
	}
};

export const getHiringAgencyPages = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/hiring-agency`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getPageById = async (id, payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/${id}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getPagesBySlug = async (slug) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/slug/${slug}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getAllCitiesPages = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/cities`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getProvinceCitiesPages = async (id) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/cities/province/${id}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getAllPagesURLs = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/page/get-all-pages-urls`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};
import React, { useEffect, useState } from 'react';
import { getContentByURL } from '../service/api';
import './PageContent.css';

const PageContent = () => {
	const [pageContent, setPageContent] = useState({});

	useEffect(() => {
		const fetchPageContent = async () => {
			const response = await getContentByURL(window.location.pathname);
			if (response && response.success) {
				setPageContent(response.data);
			}
		};
		fetchPageContent();
	}, []);

	return (
		<div className="content-container">
			<div
				className="page-content align-items-center justify-content-center"
				dangerouslySetInnerHTML={{
					__html: pageContent.content,
				}}
			></div>
		</div>
	);
};

export default PageContent;

import React from 'react';
import { useLocation } from 'react-router-dom';

const routes = [
	{ path: '/admin', label: 'Admins' },
	{ path: '/admin/pages', label: 'Pages' },
	{ path: '/admin/jobs', label: 'Jobs' },
	{ path: '/admin/blogs', label: 'Blogs' },
	{ path: '/admin/subscribers', label: 'Subscribers' },
	{ path: '/admin/contents', label: 'Contents' },
];

const AdminSidebar = () => {
	const location = useLocation();

	return (
		<nav className="sidebar h-100 pt-3 ml-3 my-5">
			<div className="d-flex flex-column justify-content-center p-3 sidebar-content">
				<ul className="navbar-nav components sidebar-menu">
					{routes.map((route, index) => {
						const isActive =
							location.pathname === route.path ||
							(location.pathname.startsWith(route.path) &&
								route.path !== '/admin');

						return (
							<li
								key={index}
								className={`nav-item nav-pills my-2 rounded-2 ${isActive ? 'active' : ''}`}
							>
								<a
									href={route.path}
									className="nav-link px-3 link-hover sidebar-link"
								>
									{route.label}
								</a>
							</li>
						);
					})}
				</ul>
			</div>
		</nav>
	);
};

export default AdminSidebar;

import { useEffect, useState } from 'react';
import { getContents, deleteContent } from '../../../service/api';
import DataTableComponent from '../../../components/DataTable/DataTableComponent';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getUserToken } from '../../../utils/auth';
import moment from 'moment';

const AdminContents = () => {
	const [contents, setContents] = useState([]);

	const columns = [
		{
			name: 'PageURL',
			selector: (row) => row.pageURL,
			sortable: true,
		},

		{
			name: 'Created Date',
			selector: (row) => moment(row.createdAt).format('MMMM DD, YYYY'),
			sortable: true,
		},
		{
			name: 'Actions',
			cell: (row) => (
				<div className="d-flex align-items-center justify-content-center ">
					<Link
						to={`/admin/contents/edit/${row._id}`}
						className="btn btn-outline-info p-1 m-1 text-nowrap"
					>
						Update
					</Link>
					<button
						className="btn btn-outline-danger p-1 m-1 text-nowrap"
						onClick={() => handleDelete(row._id)}
					>
						Delete
					</button>
				</div>
			),
			sortable: false,
		},
	];

	const handleDelete = async (id) => {
		const confirmed = await Swal.fire({
			title: 'Are you sure?',
			text: 'You will not be able to recover this page content!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, cancel!',
			reverseButtons: true,
		});

		if (confirmed.isConfirmed) {
			try {
				const response = await deleteContent({
					id,
					token: getUserToken(),
				});
				if (response.success) {
					const contentsList = contents.filter(
						(content) => content._id !== id,
					);
					setContents(contentsList);
					Swal.fire(
						'Deleted!',
						'The content post has been deleted.',
						'success',
					);
				}
			} catch (error) {
				console.log('Error deleting content post:', error);
				Swal.fire(
					'Error!',
					'An error occurred while deleting the content post.',
					'error',
				);
			}
		} else if (confirmed.dismiss === Swal.DismissReason.cancel) {
			Swal.fire(
				'Cancelled',
				'The content deletion was cancelled.',
				'info',
			);
		}
	};

	useEffect(() => {
		const fetchContents = async () => {
			const response = await getContents();
			if (response.success) {
				setContents(response.data);
			}
		};
		fetchContents();
	}, []);

	return (
		<>
			<div className="mt-5 mx-md-5 mx-3 card rounded-lg shadow-lg">
				<div className="card-header d-flex justify-content-between h3">
					<span className="d-md-block d-none"></span>
					<span className="text-primary">All Pages Contents</span>
					<Link
						to="/admin/contents/add"
						className="btn btn-outline-primary"
					>
						Add Page Content
					</Link>
				</div>
				<div className="card-body">
					<div className="">
						{contents && contents.length > 0 ? (
							<DataTableComponent
								columns={columns}
								data={contents}
								showSearch={true}
								searchKey="pageURL"
							/>
						) : (
							<div className="bg-white text-center mt-5 p-4">
								Loading ...
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default AdminContents;

import React from 'react';
import ContentForm from '../../../components/ContentForm';

const AddContent = () => {
	return (
		<>
			<ContentForm />
		</>
	);
};

export default AddContent;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getJobById } from '../../../service/api';
import UpdateJobForm from '../../../components/UpdateJobForm';

const EditJob = () => {
	const { id } = useParams();
	const [job, setJob] = useState(null);

	useEffect(() => {
		if (id) {
			const fetchJob = async () => {
				try {
					const response = await getJobById(id);
					if (response.success) {
						setJob(response.data);
					}
				} catch (error) {
					console.log('Error fetching job:', error);
				}
			};
			fetchJob();
		}
	}, []);

	return <>{job && <UpdateJobForm job={job} />}</>;
};

export default EditJob;

import { apiRequest } from '../fetch';

export const addContent = async (payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'POST',
			url: '/content',
			data: payload,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getContents = async () => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/content`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getContentById = async (id) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/content/${id}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const getContentByURL = async (url) => {
	try {
		const response = await apiRequest({
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
			url: `/content/url/?pageURL=${url}`,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		// throw error;
	}
};

export const deleteContent = async (payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'DELETE',
			url: '/content/' + payload.id,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

export const updateContent = async (id, payload) => {
	try {
		const response = await apiRequest({
			headers: {
				Authorization: `Bearer ${payload.token}`,
				'Content-Type': 'application/json',
			},
			method: 'PUT',
			url: `/content/${id}`,
			data: payload,
		});
		if (response && response.data) {
			return response.data;
		}
	} catch (error) {
		console.log(error.message);
		throw error;
	}
};

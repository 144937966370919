import React from 'react';
import { ErrorMessage, Field } from 'formik';
import Select from 'react-select';
import FieldWrapper from './FieldWrapper';

const MultiSearchSelect = ({
	label,
	name,
	options,
	values,
	setValues,
	fieldSize,
	...rest
}) => {
	const handleChange = (selectedOptions) => {
		const selectedValues = selectedOptions.map((option) => option.value);
		setValues({ ...values, [name]: selectedValues });
	};
	let defaultValue = [];
	if (name === 'degree') {
		defaultValue = options
			?.map((option) => option.options)[0]
			?.filter((option) => values[name]?.includes(option.value));
	} else {
		defaultValue = options.filter((option) =>
			values[name]?.includes(option.value),
		);
	}

	return (
		<FieldWrapper fieldSize={fieldSize}>
			<label htmlFor={name} className="form-label">
				{label}:
			</label>
			<Field
				name={name}
				component={Select}
				onChange={handleChange}
				value={defaultValue}
				isMulti={true}
				options={options}
				{...rest}
			/>
			<ErrorMessage
				name={name}
				component="div"
				className="error-message"
			/>
		</FieldWrapper>
	);
};

export default MultiSearchSelect;

import React, { useEffect, useState } from 'react';
import ContentForm from '../../../components/ContentForm';
import { useParams } from 'react-router-dom';
import { getContentById } from '../../../service/api';
import { getUserToken } from '../../../utils/auth';

const EditContent = () => {
	const { id } = useParams();
	const [content, setContent] = useState(null);

	useEffect(() => {
		if (id) {
			const fetchContent = async () => {
				try {
					const response = await getContentById(id, {
						token: getUserToken(),
					});
					if (response.success) {
						setContent(response.data);
					}
				} catch (error) {
					console.log('Error fetching content:', error);
				}
			};
			fetchContent();
		}
	}, []);

	return <>{content && <ContentForm content={content} />}</>;
};

export default EditContent;

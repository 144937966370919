import React, { useEffect } from 'react';

const ImageCarousel = ({ images }) => {
	useEffect(() => {
		// Automatically click the next button when the component mounts
		const nextButton = document.querySelector('.carousel-control-next');
		if (nextButton) {
			nextButton.click();
		}
	}, []);

	return (
		<section className="section-box">
			<div className="container">
				<div className="row mx-5 px-5 my-5 py-3">
					<div
						id="carouselExampleDark"
						className="carousel carousel-dark slide"
						data-bs-ride="carousel"
					>
						<div className="carousel-indicators">
							{images.length > 0 &&
								images.map((_, index) => (
									<button
										key={index}
										type="button"
										data-bs-target="#carouselExampleDark"
										data-bs-slide-to={index}
										className={index === 0 ? 'active' : ''}
										aria-current={
											index === 0 ? 'true' : 'false'
										}
										aria-label={`Slide ${index + 1}`}
									></button>
								))}
						</div>
						<div className="carousel-inner">
							{images.length > 0 &&
								images.map((image, index) => (
									<div
										key={index}
										className={`carousel-item ${index === 0 ? 'active' : ''}`}
										data-bs-interval={
											index === 0 ? 10000 : 2000
										}
									>
										<img
											className="d-block w-100"
											src={`${process.env.REACT_APP_BACKEND_URL}${image}`}
											alt={`Slide ${index + 1}`}
											loading="lazy"
											style={{
												maxHeight: '500px',
												objectFit: 'contain',
												backgroundColor: 'white',
											}}
										/>
									</div>
								))}
						</div>
						{images.length > 0 && (
							<>
								<button
									className="carousel-control-prev"
									type="button"
									data-bs-target="#carouselExampleDark"
									data-bs-slide="prev"
								>
									<span
										className="carousel-control-prev-icon"
										aria-hidden="true"
									></span>
									<span className="visually-hidden">
										Previous
									</span>
								</button>
								<button
									className="carousel-control-next"
									type="button"
									data-bs-target="#carouselExampleDark"
									data-bs-slide="next"
								>
									<span
										className="carousel-control-next-icon"
										aria-hidden="true"
									></span>
									<span className="visually-hidden">
										Next
									</span>
								</button>
							</>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default ImageCarousel;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBlogByURL } from '../../service/api';
import './BlogDetails.css';
import moment from 'moment';
import profile from '../../assets/images/profileLogo.png';

const BlogDetails = () => {
	const { id } = useParams();
	const [post, setPost] = useState(null);

	useEffect(() => {
		if (id) {
			const fetchPost = async () => {
				try {
					const response = await getBlogByURL(id);
					if (response?.success) {
						setPost(response.data);
					}
				} catch (error) {
					console.log('Error fetching post:', error);
				}
			};
			fetchPost();
		}
	}, [id]);

	return (
		<>
			{post ? (
				<>
					<div className="blog-container">
						<header className="blog-header">
							<h1 className="blog-h1">{post.title}</h1>
						</header>

						<div className="author-section">
							<div className="d-flex align-items-center justify-content-center">
								<img
									src={profile}
									alt="Author Image"
									className="author-image"
								/>

								<span className="author-name ms-3">
									{post.publishedBy}
								</span>
							</div>
							<div className="author-details">
								<p className="publish-date mb-0">
									Published:{' '}
									{moment(post.publishDate).format(
										'MMM DD, YYYY',
									)}
								</p>
							</div>
						</div>

						<div
							className="content  align-items-center justify-content-center"
							dangerouslySetInnerHTML={{
								__html: post.blogDescription,
							}}
						></div>
					</div>
				</>
			) : (
				<div className="bg-white text-center mt-5 p-4">Loading ...</div>
			)}
		</>
	);
};

export default BlogDetails;
